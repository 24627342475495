import { defineComponent as _defineComponent } from 'vue'
import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "status-badge" }
const _hoisted_2 = {
  key: 0,
  class: "status-badge__label"
}


export default /*@__PURE__*/_defineComponent({
  __name: 'StatusBadge',
  props: {
    status: { default: 'success' },
    label: { default: '' }
  },
  setup(__props: any) {


const STATUS = {
  success: {
    color: 'text-success',
    icon:  'icon-checkmark'
  },
  warning: {
    color: 'text-warning',
    icon:  'icon-warning'
  },
  info: {
    color: 'text-info',
    icon:  'icon-info'
  },
  error: {
    color: 'text-error',
    icon:  'icon-error'
  }
};




return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("i", {
      class: _normalizeClass(["status-badge__icon icon", {
        [STATUS[_ctx.status].icon]: true,
        [STATUS[_ctx.status].color]: true
      }])
    }, null, 2),
    _cache[0] || (_cache[0] = _createTextVNode()),
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.label), 1))
      : _createCommentVNode("", true)
  ]))
}
}

})