import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { style: {"padding":"20px"} }
const _hoisted_2 = { class: "row mt-20" }
const _hoisted_3 = { class: "col span-6" }
const _hoisted_4 = { class: "mt-20" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { class: "mt-20" }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { class: "col span-6" }
const _hoisted_9 = { class: "mt-20" }
const _hoisted_10 = { key: 0 }
const _hoisted_11 = { class: "mt-20" }
const _hoisted_12 = { key: 1 }
const _hoisted_13 = { key: 2 }

import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from '@shell/composables/useI18n';

import { Card } from '@components/Card';
import LabeledInput from '@components/Form/LabeledInput/LabeledInput.vue';
import AsyncButton from '@shell/components/AsyncButton';
import StatusBadge from '@shell/components/StatusBadge';
import Banner from '@components/Banner/Banner.vue';
import FileSelector from '@shell/components/form/FileSelector';
import { downloadFile } from '@shell/utils/download';

type RegistrationStatus = 'registering-online' | 'registering-offline' | 'registered-online' | 'registered-offline' | null;

/**
 * Registration code for online registration; empty if none or offline
 */

export default /*@__PURE__*/_defineComponent({
  __name: 'Registration',
  setup(__props) {

const store = useStore();
const { t } = useI18n(store);

const registrationCode = ref('');

/**
 * Certificate for offline registration; empty if none or online
 */
const offlineRegistrationCertificate = ref('');

/**
 * Single source for the registration status, used to define other computed properties
 */
const registrationStatus = ref(null as RegistrationStatus);

/**
 * Expiration status for the registration, both online and offline
 */
const expirationStatus = ref(null as 'success' | 'warning' | null);

/**
 * Track both registration types
 */
const isRegistered = computed(() => registrationStatus.value === 'registered-online' || registrationStatus.value === 'registered-offline');

/**
 * Track both registering progresses as generic operation to disable all the inputs
 */
const isRegistering = computed(() => registrationStatus.value === 'registering-online' || registrationStatus.value === 'registering-offline');

const isRegisteredOnline = computed(() => registrationStatus.value === 'registered-online');
const isRegisteredOffline = computed(() => registrationStatus.value === 'registered-offline');

/**
 * Track offline registration progress, to switch between file selector and async button
 */
const isRegisteringOffline = computed(() => registrationStatus.value === 'registering-offline');

/**
 * Current error list, displayed in the banner
 */
const errors = ref([] as string[]);

/**
 * Stored expiration date, retrieved from CRD
 */
const expirationDate = computed(() => 'XX/XX/XXXX');

/**
 * Reset other inputs and errors, set current state then patch the registration
 * @param type 'online' | 'offline' | 'deregister'
 * @param asyncButtonResolution Async button callback
 */
const patchRegistration = (type: 'online' | 'offline' | 'deregister', asyncButtonResolution: () => void) => {
  errors.value = [];
  setTimeout(() => {
    switch (type) {
    case 'online':
      offlineRegistrationCertificate.value = '';
      registrationStatus.value = 'registered-online';
      expirationStatus.value = 'success';
      break;
    case 'offline':
      registrationCode.value = '';
      registrationStatus.value = 'registered-offline';
      expirationStatus.value = 'warning';
      break;
    case 'deregister':
      registrationStatus.value = null;
      registrationCode.value = '';
      offlineRegistrationCertificate.value = '';
      break;
    }
    asyncButtonResolution();
  }, 2000);
};

/**
 * Handle error
 */
const onError = () => {
  errors.value.push('An error occurred');
};

/**
 * Patch CRD for online registration
 * @param asyncButtonResolution Async button callback
 */
const registerOnline = (asyncButtonResolution: () => void) => {
  registrationStatus.value = 'registering-online';
  patchRegistration('online', asyncButtonResolution);
};

/**
 * Handle download offline registration request
 * @param asyncButtonResolution Async button callback
 */
const downloadOfflineRequest = (asyncButtonResolution: (status: boolean) => void) => {
  const fileName = 'rancher-offline-registration-request.json';
  const data = '';

  setTimeout(() => {
    downloadFile(fileName, JSON.stringify(data), 'application/json')
      .then(() => asyncButtonResolution(true))
      .catch(() => asyncButtonResolution(false));
  }, 1000);
};

/**
 * Set certificate from file, then patch the registration for offline
 * @param certificate base64 encoded certificate from SCC
 */
const registerOffline = (certificate: string) => {
  registrationStatus.value = 'registering-offline';
  offlineRegistrationCertificate.value = certificate;
  patchRegistration('offline', () => {});
};

/**
 * TODO - #13387: Remove after implementing the real error handling
 * @param asyncButtonResolution Async button callback
 */
// eslint-disable-next-line no-unused-vars
const registerWithError = (asyncButtonResolution: () => void) => {
  errors.value = [];
  setTimeout(() => {
    onError();
    asyncButtonResolution();
  }, 1000);
};

/**
 * De-register handler
 * @param asyncButtonResolution Async button callback
 */
const deregister = (asyncButtonResolution: () => void) => {
  patchRegistration('deregister', asyncButtonResolution);
};

return (_ctx: any,_cache: any) => {
  const _directive_clean_html = _resolveDirective("clean-html")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", null, _toDisplayString(_unref(t)('registration.title')), 1),
    _cache[10] || (_cache[10] = _createTextVNode()),
    _withDirectives(_createElementVNode("p", null, null, 512), [
      [_directive_clean_html, _unref(t)('registration.description', {}, true)]
    ]),
    _cache[11] || (_cache[11] = _createTextVNode()),
    (errors.value.length)
      ? (_openBlock(), _createBlock(Banner, {
          key: 0,
          label: errors.value,
          color: "error",
          "data-testid": "registration-errors"
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(errors.value, (error, i) => {
              return (_openBlock(), _createElementBlock("p", { key: i }, _toDisplayString(error), 1))
            }), 128))
          ]),
          _: 1
        }, 8, ["label"]))
      : _createCommentVNode("", true),
    _cache[12] || (_cache[12] = _createTextVNode()),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_unref(Card), { showActions: false }, {
          title: _withCtx(() => [
            _createElementVNode("h2", null, _toDisplayString(_unref(t)('registration.online.title')), 1)
          ]),
          body: _withCtx(() => [
            _withDirectives(_createElementVNode("p", _hoisted_4, null, 512), [
              [_directive_clean_html, _unref(t)('registration.online.description', {}, true)]
            ]),
            _cache[2] || (_cache[2] = _createTextVNode()),
            _createVNode(LabeledInput, {
              value: registrationCode.value,
              "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((registrationCode).value = $event)),
              class: "mt-20",
              disabled: isRegistered.value || isRegistering.value,
              "label-key": "registration.online.input.label",
              "placeholder-key": "registration.online.input.placeholder",
              "data-testid": "registration-code-input"
            }, null, 8, ["value", "disabled"]),
            _cache[3] || (_cache[3] = _createTextVNode()),
            (isRegisteredOnline.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  (expirationStatus.value)
                    ? (_openBlock(), _createBlock(_unref(StatusBadge), {
                        key: 0,
                        status: expirationStatus.value,
                        label: _unref(t)(`registration.registered.description.${expirationStatus.value}`, { expirationDate: expirationDate.value }, true),
                        "data-testid": "expiration-status-online"
                      }, null, 8, ["status", "label"]))
                    : _createCommentVNode("", true),
                  _cache[1] || (_cache[1] = _createTextVNode()),
                  _createElementVNode("div", _hoisted_6, [
                    _createVNode(_unref(AsyncButton), {
                      currentPhase: "error",
                      waitingLabel: _unref(t)('registration.registered.button-cta.progress'),
                      "error-label": _unref(t)('registration.registered.button-cta.label'),
                      "data-testid": "registration-online-deregister-cta",
                      disabled: isRegistering.value,
                      onClick: deregister
                    }, null, 8, ["waitingLabel", "error-label", "disabled"])
                  ])
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_7, [
                  _createVNode(_unref(AsyncButton), {
                    class: "mt-20",
                    waitingLabel: _unref(t)('registration.online.button-cta.progress'),
                    "action-label": _unref(t)('registration.online.button-cta.label'),
                    "data-testid": "registration-online-cta",
                    disabled: isRegistered.value || isRegistering.value || !registrationCode.value,
                    onClick: registerOnline
                  }, null, 8, ["waitingLabel", "action-label", "disabled"])
                ]))
          ]),
          _: 1
        })
      ]),
      _cache[9] || (_cache[9] = _createTextVNode()),
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_unref(Card), {
          showHighlightBorder: false,
          showActions: false
        }, {
          title: _withCtx(() => [
            _createElementVNode("h2", null, _toDisplayString(_unref(t)('registration.offline.title')), 1)
          ]),
          body: _withCtx(() => [
            _withDirectives(_createElementVNode("p", _hoisted_9, null, 512), [
              [_directive_clean_html, _unref(t)('registration.offline.description', {}, true)]
            ]),
            _cache[6] || (_cache[6] = _createTextVNode()),
            _createElementVNode("div", null, [
              _createVNode(_unref(AsyncButton), {
                class: "mt-20",
                actionColor: "role-secondary",
                successColor: "role-secondary",
                waitingLabel: _unref(t)('registration.offline.button-download.progress'),
                "action-label": _unref(t)('registration.offline.button-download.label'),
                "success-label": _unref(t)('registration.offline.button-download.label'),
                "data-testid": "registration-offline-download",
                disabled: isRegistered.value,
                onClick: downloadOfflineRequest
              }, null, 8, ["waitingLabel", "action-label", "success-label", "disabled"])
            ]),
            _cache[7] || (_cache[7] = _createTextVNode()),
            (isRegisteredOffline.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                  (expirationStatus.value)
                    ? (_openBlock(), _createBlock(_unref(StatusBadge), {
                        key: 0,
                        status: expirationStatus.value,
                        label: _unref(t)(`registration.registered.description.${expirationStatus.value}`, { expirationDate: expirationDate.value }, true),
                        "data-testid": "expiration-status-offline"
                      }, null, 8, ["status", "label"]))
                    : _createCommentVNode("", true),
                  _cache[5] || (_cache[5] = _createTextVNode()),
                  _createElementVNode("div", _hoisted_11, [
                    _createVNode(_unref(AsyncButton), {
                      currentPhase: "error",
                      waitingLabel: _unref(t)('registration.registered.button-cta.progress'),
                      "error-label": _unref(t)('registration.registered.button-cta.label'),
                      "data-testid": "registration-offline-deregister-cta",
                      disabled: isRegistering.value,
                      onClick: deregister
                    }, null, 8, ["waitingLabel", "error-label", "disabled"])
                  ])
                ]))
              : (isRegisteringOffline.value)
                ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                    _createVNode(_unref(AsyncButton), {
                      class: "mt-20",
                      waitingLabel: _unref(t)('registration.offline.button-cta.progress'),
                      "action-label": _unref(t)('registration.offline.button-cta.label'),
                      "data-testid": "registration-offline-cta",
                      disabled: isRegistered.value || isRegistering.value,
                      currentPhase: 'waiting'
                    }, null, 8, ["waitingLabel", "action-label", "disabled"])
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_13, [
                    _createVNode(_unref(FileSelector), {
                      class: "role-primary mt-20",
                      label: _unref(t)('registration.offline.button-cta.label'),
                      disabled: isRegistered.value || isRegistering.value,
                      "data-testid": "registration-offline-cta",
                      onSelected: registerOffline
                    }, null, 8, ["label", "disabled"])
                  ]))
          ]),
          _: 1
        })
      ])
    ])
  ]))
}
}

})