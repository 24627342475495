// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/cjs.js??clonedRuleSet-22.use[1]!../../../node_modules/vue-loader/dist/stylePostLoader.js!../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[2]!../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[3]!../../../node_modules/xterm/css/xterm.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `button[data-v-237c04b6]{all:unset;height:0;position:relative;top:-35px;float:right;margin-right:7px;cursor:pointer}button .align-value[data-v-237c04b6]{padding-top:7px}.row.name-ns-description[data-v-237c04b6]{max-height:61px}.row .namespace-select[data-v-237c04b6]  .labeled-select{min-width:40%}.row .namespace-select[data-v-237c04b6]  .labeled-select .v-select.inline.vs--single{padding-bottom:2px}.row.flip-direction[data-v-237c04b6]{flex-direction:column}.row.flip-direction.name-ns-description[data-v-237c04b6]{max-height:none}.row.flip-direction>div[data-v-237c04b6]>*{margin-bottom:20px}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
