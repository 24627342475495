// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/cjs.js??clonedRuleSet-22.use[1]!../../../node_modules/vue-loader/dist/stylePostLoader.js!../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[2]!../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[3]!../../../node_modules/xterm/css/xterm.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `[data-v-65a1bd46]  .yaml-editor{flex:1;min-height:200px}[data-v-65a1bd46]  .yaml-editor .code-mirror .CodeMirror{position:static;height:auto;min-height:200px}[data-v-65a1bd46]  .info-box{margin-bottom:10px}.box[data-v-65a1bd46]{position:relative}.remove[data-v-65a1bd46]{position:absolute;top:10px;right:10px;padding:0}.bootOrder[data-v-65a1bd46]{display:flex;align-items:center}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
