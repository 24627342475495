// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/cjs.js??clonedRuleSet-22.use[1]!../../../../../node_modules/vue-loader/dist/stylePostLoader.js!../../../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[2]!../../../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[3]!../../../../../node_modules/xterm/css/xterm.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.popperContainer[data-v-75a53e3c]{display:contents}.popperContainer[data-v-75a53e3c] .v-popper__popper .v-popper__wrapper{box-shadow:0 6px 18px 0 rgba(0,0,0,.25),0 4px 10px 0 rgba(0,0,0,.15);border-radius:var(--border-radius-lg)}.popperContainer[data-v-75a53e3c] .v-popper__popper .v-popper__wrapper .v-popper__arrow-container{display:none}.popperContainer[data-v-75a53e3c] .v-popper__popper .v-popper__wrapper .v-popper__inner{padding:10px 0 10px 0}.dropdownTarget[data-v-75a53e3c]:focus,.dropdownTarget[data-v-75a53e3c]:focus-visible{outline:none}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
