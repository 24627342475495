// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../node_modules/@vue/cli-service/node_modules/css-loader/dist/cjs.js??clonedRuleSet-22.use[1]!../../node_modules/vue-loader/dist/stylePostLoader.js!../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[2]!../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[3]!../../node_modules/xterm/css/xterm.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.confirm-modal .btn[data-v-d1e79bd8]{margin:0 10px}.confirm-modal .body[data-v-d1e79bd8]{min-height:75px;padding:10px 0 0 15px}.confirm-modal .body p[data-v-d1e79bd8]{margin-top:10px}.confirm-modal .header[data-v-d1e79bd8]{background-color:var(--error);padding:15px 0 0 15px;height:50px}.confirm-modal .header h4[data-v-d1e79bd8]{color:#fff}.confirm-modal .footer[data-v-d1e79bd8]{border-top:1px solid var(--border);text-align:center;padding:10px 0 0 15px;height:60px}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
