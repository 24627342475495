import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, withKeys as _withKeys, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["aria-label"]

import { ref } from 'vue';
import { useClickOutside } from '@shell/composables/useClickOutside';
import { useDropdownContext } from '@components/RcDropdown/useDropdownContext';


export default /*@__PURE__*/_defineComponent({
  __name: 'RcDropdown',
  props: {
    ariaLabel: {}
  },
  emits: ['update:open'],
  setup(__props: any, { emit: __emit }) {

/**
 * Offers a list of choices to the user, such as a set of actions or functions.
 * Opened by activating RcDropdownTrigger.
 *
 * Example:
 *
 *  <rc-dropdown :aria-label="t('nav.actionMenu.label')">
 *    <rc-dropdown-trigger tertiary>
 *      <i class="icon icon-actions" />
 *    </rc-dropdown-trigger>
 *    <template #dropdownCollection>
 *      <rc-dropdown-item @click="performAction()">
 *        Action 1
 *      </rc-dropdown-item>
 *      <rc-dropdown-separator />
 *      <rc-dropdown-item @click="performAction()">
 *        Action 2
 *      </rc-dropdown-item>
 *    </template>
 *  </rc-dropdown>
 */


const emit = __emit;

const {
  isMenuOpen,
  showMenu,
  returnFocus,
  setFocus,
  provideDropdownContext,
  registerDropdownCollection,
  handleKeydown,
} = useDropdownContext(emit);

provideDropdownContext();

const popperContainer = ref(null);
const dropdownTarget = ref(null);

useClickOutside(dropdownTarget, () => showMenu(false));

const applyShow = () => {
  registerDropdownCollection(dropdownTarget.value);
  setFocus('down');
};


return (_ctx: any,_cache: any) => {
  const _component_v_dropdown = _resolveComponent("v-dropdown")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_v_dropdown, {
      "no-auto-focus": "",
      triggers: [],
      shown: _unref(isMenuOpen),
      "auto-hide": false,
      container: popperContainer.value,
      placement: 'bottom-end',
      onApplyShow: applyShow
    }, {
      popper: _withCtx(() => [
        _createElementVNode("div", {
          ref_key: "dropdownTarget",
          ref: dropdownTarget,
          class: "dropdownTarget",
          tabindex: "-1",
          role: "menu",
          "aria-orientation": "vertical",
          "dropdown-menu-collection": "",
          "aria-label": _ctx.ariaLabel || 'Dropdown Menu',
          onKeydown: [
            _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_unref(handleKeydown) && _unref(handleKeydown)(...args))),
            _cache[1] || (_cache[1] = _withKeys(($event: any) => (_unref(setFocus)('down')), ["down"])),
            _cache[2] || (_cache[2] = _withKeys(($event: any) => (_unref(setFocus)('up')), ["up"]))
          ]
        }, [
          _renderSlot(_ctx.$slots, "dropdownCollection")
        ], 40, _hoisted_1)
      ]),
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "default"),
        _cache[5] || (_cache[5] = _createTextVNode())
      ]),
      _: 3
    }, 8, ["shown", "container"]),
    _cache[6] || (_cache[6] = _createTextVNode()),
    _createElementVNode("div", {
      ref_key: "popperContainer",
      ref: popperContainer,
      class: "popperContainer",
      onKeydown: [
        _cache[3] || (_cache[3] = _withKeys(($event: any) => (_unref(showMenu)(false)), ["tab"])),
        _cache[4] || (_cache[4] = _withKeys(
//@ts-ignore
(...args) => (_unref(returnFocus) && _unref(returnFocus)(...args)), ["escape"]))
      ]
    }, null, 544)
  ], 64))
}
}

})